import { Fragment } from 'react'

import { useRouter } from 'next/router'

import usePageLogic from '@/hooks/usePageLogic'
import LoginComponent from '@/stories/login'
import HeadTag from '@components/head'

export default function LoginPage() {
  const router = useRouter()
  // set up polling and other page logic
  usePageLogic()

  return (
    <Fragment>
      <HeadTag pathname={router.pathname} path={router.pathname} />
      <LoginComponent />
    </Fragment>
  )
}
